﻿import { defineComponent, PropType } from 'vue';

import select_passengers from "../common/passenger/select_passengers.vue";

import { trip } from "../../../objects/v1_2/Booking/Trip";

import { product_search } from "../../../objects/v1_3/product/search/product_search";
import { product_search_product } from "../../../objects/v1_3/product/search/product_search_product";

import { Booking } from '../../../services/booking';
import { Product } from '../../../services/product';
import { Date_Service } from '../../../../system/services/date_service';
import { Currency_Service } from "../../../../system/services/currency";
import { passenger } from '../../../objects/v1_2/Booking/Passenger';
import { Capacity } from "../../../services/capacity";
import { booking_group } from "../../../objects/v1_2/Booking/Booking_Group"
import { capacity } from 'modules/tms/booking_engine/objects/v1_3/product/capacity/capacity';


const Book = new Booking();
const Prod = new Product();
const Currency_Services = new Currency_Service();
const Date_Services = new Date_Service();

export default defineComponent({
    name: "add-ons",
    components: {
        select_passengers,
        Capacity
        
    },
    props: {
        trip: {type: Object as PropType<trip>, default: new trip()}
    },
    data() {
        return {
            product_search: new product_search() as product_search,
            addonCount: 0,
            logging: "",
            Addons: new Array<product_search_product>(),
            showAddons: false
        }
    },
    methods: {
        async Download_Add_Ons() {
            const Prod = new Product();
            let Store = this.$store;

            this.product_search = await Prod.Get_Sales_AddOns(this.trip?.tour_id ?? ""); 
            this.product_search?.products?.forEach(async function (product: product_search_product) {
                await Prod.Get_Details(product.tour_id, Store);
            });
        },
        image(tour_details: product_search_product): string {
            return tour_details?.media_asset?.asset_url + "?anchor=center&mode=crop&width=600";
        },
        imageAltText(tour_details: product_search_product): string {
            return tour_details?.media_asset?.name ?? "Image of " + tour_details.name;
        },
        price(tour_details: product_search_product): string {
            return Currency_Services.Format_Currency(Prod.product_pricing(Book.Pricing_Age_Category(this.$store), this.$store.getters.get_Currency, tour_details), this.$store.getters.get_Currency);
        },
        get_Booking_Passengers(): passenger[] {
            return this.$store.getters.get_Booking.passengers;
        },
        get_Add_Ons(): product_search_product[] {
            const Book = new Booking();

            let Output: product_search_product[] = [];  
            let Booking_Info = this.$store.getters.get_Booking;
            let Store = this.$store;
            let main_tour_id : string = this.trip?.tour_id ?? "";
            let departure_date : string | null = this.trip?.departure_date;
            let product_detail = Prod.Get_Details_Sync(main_tour_id, Store);

            this.product_search?.products?.forEach(product => {
                if (Book.Trip_Selected(product.tour_id, Booking_Info) == false) {
                    var can_add: boolean = false;

                    product_detail.seller_information.add_ons?.forEach(addongroup => {
                        this.addonCount = addongroup.groups.length;
                        if (addongroup.name == 'Add Ons' || addongroup.name == 'Accommodation') {                      
                            addongroup.groups.forEach(add_on => {
                                if (product.tour_id == add_on.products[0]?.tour_id && add_on.products[0]?.tour_id != main_tour_id) {
                                    product.pre_tour = add_on.products[0]?.pre_tour ?? false;
                                    product.pre_tour_offset = add_on.products[0]?.pre_tour_offset ?? 0;
                                    product.post_tour = add_on.products[0]?.post_tour ?? false;
                                    product.post_tour_offset = add_on.products[0]?.post_tour_offset ?? 0;
                                    if (add_on.dates == undefined || add_on.dates.length == 0) {
                                        can_add = true;
                                    } else {
                                        add_on.dates.forEach(daterange => {
                                            var is_between = Date_Services.compare_dates_between(departure_date, daterange.date_from, daterange.date_to);
                                            if(daterange.date_from == null || daterange.date_from == "" || daterange.date_from == undefined || daterange.date_from == " " || daterange.date_from.includes("NaN")){
                                                this.logging += " | From: failed ";
                                            }
                                            if(daterange.date_to == null || daterange.date_to == "" || daterange.date_to == undefined || daterange.date_to == " " || daterange.date_to.includes("NaN")){
                                                this.logging += " | To: failed ";
                                            }
                                            if (is_between) {
                                                can_add = true;
                                            }

                                        });
                                    }
                                }
                                if (can_add) {
                                    //TMS-5708: temp holiday hack to stop potentially any other accomm. addon showing
                                    if (addongroup.name == 'Accommodation')
                                        {
                                            if (add_on.products[0]?.tour_id != '3SU') can_add = false;
                                            else if (this.$store.getters.get_Booking.passengers.length != 2) {
                                                can_add = false;
                                            }
                                        }

                                    return;
                                }
                            })
                        }
                    });

                    product.isrunning_on_departure_date = can_add;
                    if (can_add) {
                        Output.push(product);
                    }
                }
            });

            return Output;
        },
        Trip_Name(): string {
            return Prod.Get_Details_Sync(this.trip?.tour_id ?? "", this.$store).name;
        },
        Add_On_Message(): string {
            if (this.get_Add_Ons().length > 0) {
                return "Add these optional extras to get the best out of your trip.";
            }

            return "There are not optional add-ons available on this tour";
        },
        isMobile(): boolean{
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        },
        get_post_offset_Date(date: string, post_tour_offset: number): string {
            let returnDate = "";
            if (date != "") {
                const datetime = new Date(date)
                datetime.setDate(datetime.getDate() + post_tour_offset);
                let month =  datetime.getMonth() + 1 //month starts from 0 (year and date don't though....)
                returnDate =  datetime.getUTCFullYear() + "/" + month + "/" + datetime.getDate();
                returnDate = returnDate.replaceAll("/0","/");
            }
            return returnDate;
        },
        async Capacity_Check(tour_details: product_search_product): Promise<boolean> {
            
            if (tour_details.tour_id.toUpperCase().includes(import.meta.env.VITE_DEFAULT_ECO_ID.toUpperCase())) {
                return true;
            }
            
            let free_seats = 0;
            let Capa = new Capacity();
            let departuredate = this.get_post_offset_Date(this.trip?.departure_date ?? "", tour_details.post_tour_offset);
            let splitDepartureDate = departuredate.split("/") ?? [];
            const capacities = (await Capa.Get_Capacity(tour_details.tour_id, Number(splitDepartureDate[0]), Number(splitDepartureDate[1]))).capacities;
           
            capacities.forEach(capacity => {

                if(capacity.start_date_time.replaceAll("-0","-").includes(departuredate.replaceAll("/", "-") ?? "Date not found")) {
                    free_seats = capacity.free_seats;
                }
            });

            //console.log(tour_details.name + " - Date: " + departuredate + " - Available slots: " +  free_seats);
            return free_seats >= this.get_Booking_Passengers().length;
        },
        sort_Add_ons(productList: product_search_product[]): product_search_product[] {
            let SortedList: product_search_product[] = [];
            let ecoAdded : boolean = false;
            let EcoProduct: product_search_product = new product_search_product();

            productList.forEach(product => {
                if (product.tour_id.toUpperCase().includes(import.meta.env.VITE_DEFAULT_ECO_ID.toUpperCase())) {
                    ecoAdded = true;
                    EcoProduct = product;
                }
                else {
                    SortedList.push(product);
                }
                
            });
            if (ecoAdded) {
                SortedList.push(EcoProduct);
            }
            return SortedList;
        },
        isEcoScheme(tour_details: product_search_product): boolean {
            if(tour_details.tour_id.toUpperCase().includes(import.meta.env.VITE_DEFAULT_ECO_ID.toUpperCase())) {
                return true;
            }
            return false;
        },
        async Load_capacities(addons: product_search_product[])  {
            addons.forEach(addon => {
                this.Capacity_Check(addon).then(result => { 
                    if(result){
                        this.Addons.push(addon);
                    }

                 });
                               
            })
            
        },
        get_Basket(): booking_group {
            return this.$store.getters.get_Basket;
        },
        cartNotEmpty(){
            let returnvalue = true;
            let basket = this.get_Basket();

            if(basket.passengers.toString() === null || basket.passengers.toString() === undefined || basket.passengers.toString() === ''){
                returnvalue = false;
            }

            if(basket.trips[0].departure_date == null || basket.trips[0].departure_date == undefined || basket.trips[0].departure_date == ''){
                returnvalue = false;
            }


            return returnvalue;
        }
    },
    mounted() {
        this.Download_Add_Ons().then(() => {
            this.Load_capacities(this.get_Add_Ons()).then(() => {
                this.showAddons = true;
            });
        });

    }
})