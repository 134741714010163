﻿import { room } from "./room";
 
export const Room_Information: room = {
    'rooms': [
  {
    "passengers": 1,
    "room_types": [
      {
        "name": "1x Dorm Bed",
        "room_type_combinations": [
          {
            "occupants": 1,
            "name": "Dorm Bed",
            "passenger_links": []
          }
        ]
      },
      {
        "name": "1x Single Room",
        "room_type_combinations": [
          {
            "occupants": 1,
            "name": "Single Room",
            "passenger_links": []
          }
        ]
      }
    ]
  },
  {
    "passengers": 2,
    "room_types": [
      {
        "name": "1x Double Room",
        "room_type_combinations": [
          {
            "occupants": 2,
            "name": "Double Room",
            "passenger_links": []
          }
        ]
      },
      {
        "name": "1x Private Twin Room",
        "room_type_combinations": [
          {
            "occupants": 2,
            "name": "Private Twin Room",
            "passenger_links": []
          }
        ]
      },
      {
        "name": "1x Twin Room",
        "room_type_combinations": [
          {
            "occupants": 2,
            "name": "Twin Room",
            "passenger_links": []
          }
        ]
      },
      {
        "name": "2x Dorm Beds",
        "room_type_combinations": [
          {
            "occupants": 1,
            "name": "Dorm Bed",
            "passenger_links": []
          },
          {
            "occupants": 1,
            "name": "Dorm Bed",
            "passenger_links": []
          }
        ]
      },
      {
        "name": "2x Single Rooms",
        "room_type_combinations": [
          {
            "occupants": 1,
            "name": "Single Room",
            "passenger_links": []
          },
          {
            "occupants": 1,
            "name": "Single Room",
            "passenger_links": []
          }
        ]
      }
    ]
  },
  {
    "passengers": 3,
    "room_types": [
      {
        "name": "1x Family 3 Room",
        "room_type_combinations": [
          {
            "occupants": 3,
            "name": "Family 3",
            "passenger_links": []
          }
        ]
      },
      {
        "name": "1x Private Triple Room",
        "room_type_combinations": [
          {
            "occupants": 3,
            "name": "Private Triple Room",
            "passenger_links": []
          }
        ]
      },
      {
        "name": "1x Single Room, 1x Double Room",
        "room_type_combinations": [
          {
            "occupants": 1,
            "name": "Single Room",
            "passenger_links": []
          },
          {
            "occupants": 2,
            "name": "Double Room",
            "passenger_links": []
          }
        ]
      },
      {
        "name": "1x Single Room, 1x Twin Room",
        "room_type_combinations": [
          {
            "occupants": 1,
            "name": "Single Room",
            "passenger_links": []
          },
          {
            "occupants": 2,
            "name": "Twin Room",
            "passenger_links": []
          }
        ]
      },
      {
        "name": "3x Dorm Beds",
        "room_type_combinations": [
          {
            "occupants": 1,
            "name": "Dorm Bed",
            "passenger_links": []
          },
          {
            "occupants": 1,
            "name": "Dorm Bed",
            "passenger_links": []
          },
          {
            "occupants": 1,
            "name": "Dorm Bed",
            "passenger_links": []
          }
        ]
      }
    ]
  },
  {
    "passengers": 4,
    "room_types": [
      {
        "name": "1x Double Room, 1x Twin Room",
        "room_type_combinations": [
          {
            "occupants": 2,
            "name": "Double Room",
            "passenger_links": []
          },
          {
            "occupants": 2,
            "name": "Twin Room",
            "passenger_links": []
          }
        ]
      },
      {
        "name": "1x Family 4 Room",
        "room_type_combinations": [
          {
            "occupants": 4,
            "name": "Family 4",
            "passenger_links": []
          }
        ]
      },
      {
        "name": "1x Private Quad Room",
        "room_type_combinations": [
          {
            "occupants": 4,
            "name": "Private Quad Room",
            "passenger_links": []
          }
        ]
      },
      {
        "name": "1x Single Room, 1x Family 3",
        "room_type_combinations": [
          {
            "occupants": 1,
            "name": "Single Room",
            "passenger_links": []
          },
          {
            "occupants": 3,
            "name": "Family 3",
            "passenger_links": []
          }
        ]
      },
      {
        "name": "2x Double Rooms",
        "room_type_combinations": [
          {
            "occupants": 2,
            "name": "Double Room",
            "passenger_links": []
          },
          {
            "occupants": 2,
            "name": "Double Room",
            "passenger_links": []
          }
        ]
      },
      {
        "name": "2x Twin Rooms",
        "room_type_combinations": [
          {
            "occupants": 2,
            "name": "Twin Room",
            "passenger_links": []
          },
          {
            "occupants": 2,
            "name": "Twin Room",
            "passenger_links": []
          }
        ]
      },
      {
        "name": "4x Dorm Beds",
        "room_type_combinations": [
          {
            "occupants": 1,
            "name": "Dorm Bed",
            "passenger_links": []
          },
          {
            "occupants": 1,
            "name": "Dorm Bed",
            "passenger_links": []
          },
          {
            "occupants": 1,
            "name": "Dorm Bed",
            "passenger_links": []
          },
          {
            "occupants": 1,
            "name": "Dorm Bed",
            "passenger_links": []
          }
        ]
      }
    ]
  },
  {
    "passengers": 5,
    "room_types": [
      {
        "name": "1x Double Room, 1x Family 3",
        "room_type_combinations": [
          {
            "occupants": 2,
            "name": "Double Room",
            "passenger_links": []
          },
          {
            "occupants": 3,
            "name": "Family 3",
            "passenger_links": []
          }
        ]
      },
      {
        "name": "1x Single Room, 1x Family 4",
        "room_type_combinations": [
          {
            "occupants": 1,
            "name": "Single Room",
            "passenger_links": []
          },
          {
            "occupants": 4,
            "name": "Family 4",
            "passenger_links": []
          }
        ]
      },
      {
        "name": "1x Single Room, 2x Double Room",
        "room_type_combinations": [
          {
            "occupants": 1,
            "name": "Single Room",
            "passenger_links": []
          },
          {
            "occupants": 2,
            "name": "Double Room",
            "passenger_links": []
          },
          {
            "occupants": 2,
            "name": "Double Room",
            "passenger_links": []
          }
        ]
      },
      {
        "name": "1x Single Room, 2x Twin Room",
        "room_type_combinations": [
          {
            "occupants": 1,
            "name": "Single Room",
            "passenger_links": []
          },
          {
            "occupants": 2,
            "name": "Twin Room",
            "passenger_links": []
          },
          {
            "occupants": 2,
            "name": "Twin Room",
            "passenger_links": []
          }
        ]
      },
      {
        "name": "1x Twin Room, 1x Family 3",
        "room_type_combinations": [
          {
            "occupants": 2,
            "name": "Twin Room",
            "passenger_links": []
          },
          {
            "occupants": 3,
            "name": "Family 3",
            "passenger_links": []
          }
        ]
      },
      {
        "name": "5x Dorm Bed",
        "room_type_combinations": [
          {
            "occupants": 1,
            "name": "Dorm Bed",
            "passenger_links": []
          },
          {
            "occupants": 1,
            "name": "Dorm Bed",
            "passenger_links": []
          },
          {
            "occupants": 1,
            "name": "Dorm Bed",
            "passenger_links": []
          },
          {
            "occupants": 1,
            "name": "Dorm Bed",
            "passenger_links": []
          },
          {
            "occupants": 1,
            "name": "Dorm Bed",
            "passenger_links": []
          }
        ]
      }
    ]
  },
  {
    "passengers": 6,
    "room_types": [
      {
        "name": "1x Double Room, 2x Twin Room",
        "room_type_combinations": [
          {
            "occupants": 2,
            "name": "Double Room",
            "passenger_links": []
          },
          {
            "occupants": 2,
            "name": "Twin Room",
            "passenger_links": []
          },
          {
            "occupants": 2,
            "name": "Twin Room",
            "passenger_links": []
          }
        ]
      },
      {
        "name": "1x Private Twin Room, 1x Private Quad Room",
        "room_type_combinations": [
          {
            "occupants": 2,
            "name": "Private Twin Room",
            "passenger_links": []
          },
          {
            "occupants": 4,
            "name": "Private Quad Room",
            "passenger_links": []
          }
        ]
      },
      {
        "name": "1x Single Room, 1x Double Room, 1x Family 3",
        "room_type_combinations": [
          {
            "occupants": 1,
            "name": "Single Room",
            "passenger_links": []
          },
          {
            "occupants": 2,
            "name": "Double Room",
            "passenger_links": []
          },
          {
            "occupants": 3,
            "name": "Family 3",
            "passenger_links": []
          }
        ]
      },
      {
        "name": "1x Single Room, 1x Twin Room, 1x Family 3",
        "room_type_combinations": [
          {
            "occupants": 1,
            "name": "Single Room",
            "passenger_links": []
          },
          {
            "occupants": 2,
            "name": "Twin Room",
            "passenger_links": []
          },
          {
            "occupants": 3,
            "name": "Family 3",
            "passenger_links": []
          }
        ]
      },
      {
        "name": "2x Dorm Bed, 1x Private Quad Room",
        "room_type_combinations": [
          {
            "occupants": 1,
            "name": "Dorm Bed",
            "passenger_links": []
          },
          {
            "occupants": 1,
            "name": "Dorm Bed",
            "passenger_links": []
          },
          {
            "occupants": 4,
            "name": "Private Quad Room",
            "passenger_links": []
          }
        ]
      },
      {
        "name": "2x Double Room, 1x Twin Room",
        "room_type_combinations": [
          {
            "occupants": 2,
            "name": "Double Room",
            "passenger_links": []
          },
          {
            "occupants": 2,
            "name": "Double Room",
            "passenger_links": []
          },
          {
            "occupants": 2,
            "name": "Twin Room",
            "passenger_links": []
          }
        ]
      },
      {
        "name": "2x Single Room, 1x Family 4",
        "room_type_combinations": [
          {
            "occupants": 1,
            "name": "Single Room",
            "passenger_links": []
          },
          {
            "occupants": 1,
            "name": "Single Room",
            "passenger_links": []
          },
          {
            "occupants": 4,
            "name": "Family 4",
            "passenger_links": []
          }
        ]
      },
      {
        "name": "2x Single Room, 2x Double Room",
        "room_type_combinations": [
          {
            "occupants": 1,
            "name": "Single Room",
            "passenger_links": []
          },
          {
            "occupants": 1,
            "name": "Single Room",
            "passenger_links": []
          },
          {
            "occupants": 2,
            "name": "Double Room",
            "passenger_links": []
          },
          {
            "occupants": 2,
            "name": "Double Room",
            "passenger_links": []
          }
        ]
      },
      {
        "name": "2x Single Room, 2x Twin Room",
        "room_type_combinations": [
          {
            "occupants": 1,
            "name": "Single Room",
            "passenger_links": []
          },
          {
            "occupants": 1,
            "name": "Single Room",
            "passenger_links": []
          },
          {
            "occupants": 2,
            "name": "Twin Room",
            "passenger_links": []
          },
          {
            "occupants": 2,
            "name": "Twin Room",
            "passenger_links": []
          }
        ]
      },
      {
        "name": "3x Dorm Bed, 1x Private Triple Room",
        "room_type_combinations": [
          {
            "occupants": 1,
            "name": "Dorm Bed",
            "passenger_links": []
          },
          {
            "occupants": 1,
            "name": "Dorm Bed",
            "passenger_links": []
          },
          {
            "occupants": 1,
            "name": "Dorm Bed",
            "passenger_links": []
          },
          {
            "occupants": 3,
            "name": "Private Triple Room",
            "passenger_links": []
          }
        ]
      },
      {
        "name": "3x Double Room",
        "room_type_combinations": [
          {
            "occupants": 2,
            "name": "Double Room",
            "passenger_links": []
          },
          {
            "occupants": 2,
            "name": "Double Room",
            "passenger_links": []
          },
          {
            "occupants": 2,
            "name": "Double Room",
            "passenger_links": []
          }
        ]
      },
      {
        "name": "3x Twin Room",
        "room_type_combinations": [
          {
            "occupants": 2,
            "name": "Twin Room",
            "passenger_links": []
          },
          {
            "occupants": 2,
            "name": "Twin Room",
            "passenger_links": []
          },
          {
            "occupants": 2,
            "name": "Twin Room",
            "passenger_links": []
          }
        ]
      },
      {
        "name": "4x Dorm Bed, 1x Private Twin Room",
        "room_type_combinations": [
          {
            "occupants": 1,
            "name": "Dorm Bed",
            "passenger_links": []
          },
          {
            "occupants": 1,
            "name": "Dorm Bed",
            "passenger_links": []
          },
          {
            "occupants": 1,
            "name": "Dorm Bed",
            "passenger_links": []
          },
          {
            "occupants": 1,
            "name": "Dorm Bed",
            "passenger_links": []
          },
          {
            "occupants": 2,
            "name": "Private Twin Room",
            "passenger_links": []
          }
        ]
      },
      {
        "name": "6x Dorm Beds",
        "room_type_combinations": [
          {
            "occupants": 1,
            "name": "Dorm Bed",
            "passenger_links": []
          },
          {
            "occupants": 1,
            "name": "Dorm Bed",
            "passenger_links": []
          },
          {
            "occupants": 1,
            "name": "Dorm Bed",
            "passenger_links": []
          },
          {
            "occupants": 1,
            "name": "Dorm Bed",
            "passenger_links": []
          },
          {
            "occupants": 1,
            "name": "Dorm Bed",
            "passenger_links": []
          },
          {
            "occupants": 1,
            "name": "Dorm Bed",
            "passenger_links": []
          }
        ]
      }
    ]
  },
  {
    "passengers": 7,
    "room_types": [
      {
        "name": "7x Dorm Beds",
        "room_type_combinations": [
          {
            "occupants": 1,
            "name": "Dorm Bed",
            "passenger_links": []
          },
          {
            "occupants": 1,
            "name": "Dorm Bed",
            "passenger_links": []
          },
          {
            "occupants": 1,
            "name": "Dorm Bed",
            "passenger_links": []
          },
          {
            "occupants": 1,
            "name": "Dorm Bed",
            "passenger_links": []
          },
          {
            "occupants": 1,
            "name": "Dorm Bed",
            "passenger_links": []
          },
          {
            "occupants": 1,
            "name": "Dorm Bed",
            "passenger_links": []
          },
          {
            "occupants": 1,
            "name": "Dorm Bed",
            "passenger_links": []
          }
        ]
      }
    ]
  },
  {
    "passengers": 8,
    "room_types": [
      {
        "name": "8x Dorm Beds",
        "room_type_combinations": [
          {
            "occupants": 1,
            "name": "Dorm Bed",
            "passenger_links": []
          },
          {
            "occupants": 1,
            "name": "Dorm Bed",
            "passenger_links": []
          },
          {
            "occupants": 1,
            "name": "Dorm Bed",
            "passenger_links": []
          },
          {
            "occupants": 1,
            "name": "Dorm Bed",
            "passenger_links": []
          },
          {
            "occupants": 1,
            "name": "Dorm Bed",
            "passenger_links": []
          },
          {
            "occupants": 1,
            "name": "Dorm Bed",
            "passenger_links": []
          },
          {
            "occupants": 1,
            "name": "Dorm Bed",
            "passenger_links": []
          },
          {
            "occupants": 1,
            "name": "Dorm Bed",
            "passenger_links": []
          }
        ]
      }
    ]
  },
  {
    "passengers": 9,
    "room_types": [
      {
        "name": "9x Dorm Bed",
        "room_type_combinations": [
          {
            "occupants": 1,
            "name": "Dorm Bed",
            "passenger_links": []
          },
          {
            "occupants": 1,
            "name": "Dorm Bed",
            "passenger_links": []
          },
          {
            "occupants": 1,
            "name": "Dorm Bed",
            "passenger_links": []
          },
          {
            "occupants": 1,
            "name": "Dorm Bed",
            "passenger_links": []
          },
          {
            "occupants": 1,
            "name": "Dorm Bed",
            "passenger_links": []
          },
          {
            "occupants": 1,
            "name": "Dorm Bed",
            "passenger_links": []
          },
          {
            "occupants": 1,
            "name": "Dorm Bed",
            "passenger_links": []
          },
          {
            "occupants": 1,
            "name": "Dorm Bed",
            "passenger_links": []
          },
          {
            "occupants": 1,
            "name": "Dorm Bed",
            "passenger_links": []
          }
        ]
      }
    ]
  },
  {
    "passengers": 10,
    "room_types": [
      {
        "name": "10x Dorm Bed",
        "room_type_combinations": [
          {
            "occupants": 1,
            "name": "Dorm Bed",
            "passenger_links": []
          },
          {
            "occupants": 1,
            "name": "Dorm Bed",
            "passenger_links": []
          },
          {
            "occupants": 1,
            "name": "Dorm Bed",
            "passenger_links": []
          },
          {
            "occupants": 1,
            "name": "Dorm Bed",
            "passenger_links": []
          },
          {
            "occupants": 1,
            "name": "Dorm Bed",
            "passenger_links": []
          },
          {
            "occupants": 1,
            "name": "Dorm Bed",
            "passenger_links": []
          },
          {
            "occupants": 1,
            "name": "Dorm Bed",
            "passenger_links": []
          },
          {
            "occupants": 1,
            "name": "Dorm Bed",
            "passenger_links": []
          },
          {
            "occupants": 1,
            "name": "Dorm Bed",
            "passenger_links": []
          },
          {
            "occupants": 1,
            "name": "Dorm Bed",
            "passenger_links": []
          }
        ]
      }
    ]
  }
]
};

            