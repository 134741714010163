﻿import { defineComponent } from 'vue';

import { Web_Engine } from '../../../../services/web_engine';

import { filters } from '../../../../objects/v1_3/web_engine/filters';
import { filter } from '../../../../objects/v1_3/web_engine/filter';
import { local_filter } from '../../../../objects/front_end/search/local_filter';

import { filtertag } from '../../../../objects/v1_3/web_engine/filtertag';

const Web_Engines = new Web_Engine();

export default defineComponent({
    name: "searchButtons",
    components: {
    },
    props: {
        remove_filter: String
    },
    emits: [
        "onFilterChange", "onTagChange"
    ],
    data() {
        return {
            isCalendarOpen: false,
            filterStatusList: [],
            openFilter: new local_filter() as local_filter,
            search_result: ""
        }

    },
    methods: {
        async download_filters(): Promise<void> {
            this.$store.commit("Setup_Filter", { Filter_Items: await Web_Engines.Get_Filters() });
        },
        getFilters(): local_filter[] {
            let Output: local_filter[] = [];

            this.$store.getters.get_Search_Filters.forEach((filter: local_filter) => {
                if (filter.name == import.meta.env.VITE_DEFAULT_FILTER_MULTI_SELECT) {
                    Output.push(filter);
                }
            });

            return Output;
        },
        getTags(): filtertag[] {
            let Output: filtertag[] = [];

            this.$store.getters.get_Search_Filters.forEach((filter: filter) => {
                if (filter.name == import.meta.env.VITE_DEFAULT_FILTER_MULTI_SELECT) {
                    filter.tags.forEach((tag: filtertag) => {
                        Output.push(tag);
                    });
                }
            });

            return Output;
        },
        toggleTag(Tag: filtertag) {
            this.$store.commit("Toggle_Tags", { Tag: Tag });
            this.onTagChange();
        },
        onTagChange() {
            this.$emit("onTagChange");
        },
        tag_Selected(Tag: filtertag): boolean {
            let Return_Status: boolean = false;

            this.$store.getters.get_Search_Tags.forEach((tag: filtertag) => {
                if (tag.tag_id == Tag.tag_id) {
                    Return_Status = true;
                }
            });

            return Return_Status;
        },
        getFilterMessage(tags: filtertag[]): string {
            let count: number = 0;

            tags.forEach((tag: filtertag) => {
                if (this.tag_Selected(tag)) {
                    count++;
                }
            });

            if(count == 0) {
                return "";
            }
            else if(count == 1) {
                return "1 Filter Selected";
            }
            else {
                return count + " Filters Selected";
            }

        },   
        toggleFilter(filter_input: local_filter) {
            filter_input.isOpen = !filter_input.isOpen;
            if (filter_input.isOpen) {
                this.openFilter = filter_input;
            } else {
                this.openFilter = new local_filter();
            }

            this.getFilters().forEach((item_filter: local_filter) => {
                if (item_filter.name != filter_input.name) {
                    item_filter.isOpen = false;
                }
            });
        },
        selectFilterTag(filter: local_filter, tag: filtertag) {
            filter.selectedTag = tag;
            filter.isOpen = false;
            this.onFilterChange();
        },
        Get_Selected_Filters(): filtertag[] {
            let selectedFilterTags: filtertag[] = [];

            this.getFilters().forEach((filterstatus: local_filter) => {
                if (filterstatus.selectedTag.tag_id != "") {
                    selectedFilterTags.push({
                        filter_name: filterstatus.name,
                        tag_id: filterstatus.selectedTag.tag_id,
                        tag_name: filterstatus.selectedTag.tag_name,
                        name: filterstatus.selectedTag.name
                    });
                }
            });

            return selectedFilterTags;
        },
        onFilterChange() {
            this.$emit("onFilterChange", this.Get_Selected_Filters());

            var Search_Result = "";
            this.Get_Selected_Filters().forEach((selected_filter: filtertag) => {
                Search_Result += " " + selected_filter.tag_name;
            });

            this.$store.commit("Set_Search_Result", { Result: Search_Result });
        },
        onClickAway(name: string, filter: local_filter) {
        },
        Clear_Filter() {
            this.$store.getters.get_Search_Filters.forEach((filter: local_filter) => {
                filter.selectedTag = filter.tags[0];
            });

            this.onFilterChange();
        },
        search_results(): string {
            return this.search_result;
        }
    },
    mounted() {
        this.download_filters();
    }
})