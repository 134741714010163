﻿<template>

    <div class="basket-title text-center mb-8">
        <h3 class="uppercase mb-4">Payment Summary</h3>
        <h5 class="mb-0 text-center"><span class="font-normal">Booking Reference </span>{{this.$store.getters.get_Booking.group_booking_id}}</h5>
    </div>
    <div class="summary-trip section my-4">

        <div class="row item py-1 leading-normal" v-for="trip in this.$store.getters.get_Booking.trips">
            <div class="w-full font-semibold">
                {{trip.tour_name}}
            </div>
            <div class="breakdown flex flex-wrap w-full my-2 text-sm text-grey-default leading-normal">
                <div class="breakdown-date flex-0 w-32">
                    {{get_Departre_Date(trip)}}
                </div>
                <div class="breakdown-pax flex-1">
                    <div class="items flex flex-wrap">
                        <div class="w-4/12 text-right"></div>
                        <div class="w-4/12 text-right">Price</div>
                        <div class="w-4/12 text-right" :hidden="isGross == 'G'" >Commission</div>
                    </div>
                    <div class="items flex flex-wrap" v-for="passenger in trip.passengers">
                        <div class="w-4/12 text-right">{{getPassenger(passenger.passenger_identifier)}}</div>
                        <div class="w-4/12 text-right">{{get_Price(passenger.price)}}</div>
                        <div class="w-4/12 text-right" :hidden="isGross == 'G'" >{{get_Price(passenger.commission)}}</div>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <hr />
    <div class="summary-price section" :hidden="!isDiscounted" >
        <div class="row discount flex py-1">
            <div class="flex-0 w-60">Discount <span>({{get_Discount_Percentage()}})</span></div>
            <div class="flex-1 text-right text-status-success">{{get_Discount()}}</div>
        </div>
        <div class="row item flex py-1">
            <div class="flex-0 w-60">Trip Price</div>
            <div class="flex-1 text-right">{{get_Full_Total()}}</div>
        </div>
    </div>

    <hr />

    <div class="summary-net-price section">
        <div class="row total flex py-1">
            <div class="flex-0 w-60 leading-normal">Total</div>
            <div class="flex-1 text-right amount">{{get_Full_Total()}}</div>
        </div>
        <div class="row net flex py-1" :hidden="isGross == 'G'" >
            <div class="flex-0 w-60">NETT</div>
            <div class="flex-1 text-right amount">{{get_Nett_Total()}}</div>
        </div>
    </div>

    <hr />

</template>

<script lang="ts" src="./payment_summary.ts"></script>