﻿import { defineComponent } from 'vue';

import header_menu from "../header/menu.vue"
import footer_item from "../base/common/footer.vue"

import add_ons from "../base/3_cart/add-ons.vue"
import tours from "../base/3_cart/tours.vue"
import basket from "../base/common/basket.vue"
import basket_header from "../base/common/header.vue"
import trip_summary from "../base/3_cart/summary.vue"

import { Booking } from "../../services/booking";

import { booking_group } from "../../objects/v1_2/Booking/Booking_Group"
import { booking_search_group } from "../../objects/v1_2/Booking_Details/booking_search_group"

const Book = new Booking();

export default defineComponent({
    components: {
        footer_item,
        header_menu,
        add_ons,
        tours,
        basket,
        basket_header,
        trip_summary
    },
    methods: {
        async Get_Quote() {            
            this.$store.commit("Setup_Booking", { Booking_Details: await Book.Quote_Booking(this.$store) });           
        },
        get_Basket(): booking_group {
            return this.$store.getters.get_Basket;
        },
        total_tours(): string {
            if (this.$store.getters.get_Basket.trips.length == 1) {
                return "1 Tour";
            }

            return this.$store.getters.get_Basket.trips.length + " Tours";
        },
        header_text(): string {
            return "You have <strong>" + this.total_tours() + "</strong> and <strong>" + this.total_add_ons() + "</strong > in your basket.";
        },        
        total_add_ons(): string {
            let Add_Ons: number = Book.getTotal_Add_Ons(this.$store.getters.get_Basket);

            if (Add_Ons == 0) {
                return "no add-ons";
            }

            if (Add_Ons == 1) {
                return "1 add-on";
            }

            return Add_Ons + " add-ons";
        },
        scrollBehavior() {
            window.scrollTo(0, 0)
        },
        cartEmpty(){
            let returnvalue = false;
            let basket = this.get_Basket();

            if(basket.passengers.toString() === null || basket.passengers.toString() === undefined || basket.passengers.toString() === ''){
                returnvalue = true;
            }

            if(basket.trips[0].departure_date == null || basket.trips[0].departure_date == undefined || basket.trips[0].departure_date == ''){
                returnvalue = true;
            }

            return returnvalue;
        }
    },
    mounted() {
        this.Get_Quote();
    }
})