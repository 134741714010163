﻿<template>
  <fieldset class="option-group accommodation">
    <div x-ref="radiogroup">
      <div v-for="room in get_Room_List()" :class="{ '': room_selected != room.name, checked: room_selected == room.name }">
        <!--available rooms-->
        <div v-if="get_Room_Available(room)">
          <div class="item">
            <div class="option-input">
              <input :id="get_ID(room)" type="radio" :checked="room_selected == room.name" :name="get_ID(room)" @click="select_room_type(room)" />
            </div>
            <div class="option-content">
              <label :for="get_ID(room)">
                <span class="room">
                  {{ room.name }}
                </span>
                <span :hidden="get_Room_Available(room)"> - Phone for Room Combination </span>
                <span class="price">
                  {{ get_Price(room) }} 
                </span>
                <span class="price pt-4" v-if="get_Price(room) != ''">
                  (Private Upgrade)
                </span>
              </label>
            </div>
            <div class="option-content"></div>
          </div>
          <div class="rounded-b-lg border-solid border-inherit  -mt-4">
            <label class="p-4">{{get_room_description(room.name)}}</label>
          </div>
        </div>

        <!--shows room but greyed out rooms-->
        <div v-else>
          <div class="item bg-grey-light rounded-t-lg" >
            <div class="option-input">
              <input :disabled="true" :id="get_ID(room)" type="radio" :checked="false" :name="get_ID(room)" @click="select_room_type(room)" />
            </div>
            <div class="option-content">
              <label :for="get_ID(room)">
                <span class="room">
                  (Unavailable) {{ room.name }} 
                </span>
                <span :hidden="get_Room_Available(room)"> - Phone for this room Combination </span>
                <span class="price">
                  {{ get_Price(room) }} 
                </span>
              </label>
            </div>
            <div class="option-content"></div>
          </div>
          <div >
            <label class="p-4 ">(Unavailable) {{get_room_description(room.name)}}</label>
          </div>   
        </div>       
      </div>
    </div>
  </fieldset>
</template>

<script lang="ts" src="./sales_group_room_type.ts"></script>
