﻿import { defineComponent } from 'vue';
import { week_day } from '../../../objects/front_end/calendar/week_day';
import { local_filter } from '../../../objects/front_end/search/local_filter';

import { filtertag } from "../../../objects/v1_3/web_engine/filtertag";
import calendar from "./calendar/calendar.vue";
import filter_buttons from "./tour_list/filter_buttons.vue";
import filter_list from "./tour_list/filter_list.vue";
import { Booking } from "../../../services/booking";
import agent_options from "./agent_options.vue";
import { Product } from '../../../services/product';

const Prod: Product = new Product();

export default defineComponent({
    name: "searchfilter",
    components: {
        calendar,
        filter_buttons,
        filter_list,
        agent_options
    },
    emits: [
        "onFilterChange", "onSearchChange", "onTagChange", "tourSelected"
    ],
    props: {
        remove_filter: String
    },
    data() {
        return {
            isCalendarOpen: false,
            filterMenu: true,
            tour_search: "",
            DepartureAdded: false,
            AgentShown: false,
            showCart: false
        }
        
    },
    methods: {
        onFilterChanges() {
            this.$emit("onFilterChange");
        },
        onSearchChanges(dep_date_from: week_day, dep_date_to: week_day) {
            this.isCalendarOpen = false;
            this.$emit("onSearchChange", dep_date_from, dep_date_to);
        },
        isAgent_Login(): boolean {
            return this.$store.getters.get_Agent_Login?.valid_login == true;
        },
        GetBrand(): string {
            return String(import.meta.env.VITE_DEFAULT_CLASS);
        },
        onTagChanges() {
            this.$emit("onTagChange");
        },
        onFilterClickAway() {
        },
        Tour_Selected(travel_date: string, tour_id: string) {
            this.$emit("tourSelected", travel_date, tour_id);
        },
        HideDropdownFilters(): boolean {
            return import.meta.env.VITE_DEFAULT_HIDE_DROPDOWN_FILTERS == "true";
        },
        async Open_Calendar() {
            this.isCalendarOpen = !this.isCalendarOpen;
            if (this.isCalendarOpen == true) {
                await this.$refs.calendar.Calendar_Update();
            }
        },
        async Search_Products(search_product: string) {
            window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
            window.dataLayer.push({
                event: "search",
                search_term: search_product
            });
            this.$store.commit("Set_Search_Products", { Search_Product: search_product });
            this.$emit("onTagChange");
        },
        Clear_Filter() {
            this.Search_Products("");
            this.clear_search();
            this.$store.commit("Reset_Filter_Tags");
            this.$refs.filter_list.Clear_Filter();
            this.DepartureAdded = false;
        },
        clear_search() {
            this.tour_search = "";
        },
        search_result(): string {
            let Search_Result: string = "";

            this.$store.getters.get_Search_Filter_Tags.forEach((tag: any) => {
                if(!Search_Result.includes(tag.name)){
                    if(tag.filter_name.toLowerCase().includes("departing") || tag.filter_name.toLowerCase().includes("departure")){
                        Search_Result += "departing from " + tag.name + " ";
                        this.DepartureAdded = true;
                    }
                    else if(tag.filter_name == "Month"){
                        Search_Result += "in " + tag.name + " ";
                    }
                    else{
                        Search_Result += tag.name + " ";
                    }
                }
            });

            return Search_Result;
        },
        display_search_box(): boolean {
            return import.meta.env.VITE_DEFAULT_DISPLAY_TOUR_SEARCH == "true";
        },
        cart_count(): number {

            if ((this.$store.getters.get_Basket.trips.length == 1) && (this.$store.getters.get_Basket.trips[0].tour_id == "")) {
                return 0;
            }

            const Book = new Booking();
            return this.$store.getters.get_Basket.trips.length + Book.getTotal_Add_Ons(this.$store.getters.get_Basket);

        },
        get_Tour_Details(tour_id: string): product_detail {
            return Prod.Get_Details_Sync(tour_id, this.$store);
        },
        isAAT(): boolean {
            if (import.meta.env.VITE_DEFAULT_PARENT == "AAT") {
                return true;
            }
            return false;
        },
        isMobile(): boolean{
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        },
        showAgentPopup() {
            if(this.isAgent_Login() && !this.isAAT() && this.AgentShown == false &&
            import.meta.env.VITE_DEFAULT_AGENT_TOUR_POPUP == "True"){
                return true;
            }
            return false;
        }

    },
    mounted() {
    }
})