﻿import { defineComponent } from 'vue';

import { Web_Engine } from '../../../../services/web_engine';

import { filter } from '../../../../objects/v1_3/web_engine/filter';
import { filtertag } from '../../../../objects/v1_3/web_engine/filtertag';
import { local_filter } from '../../../../objects/front_end/search/local_filter';
import { filters } from 'modules/tms/booking_engine/objects/v1_3/web_engine/filters';

const Web_Engines = new Web_Engine();

export default defineComponent({
    name: "searchfilter",
    components: {
    },
    emits: [
        "onFilterChange"
    ],
    props: {
        remove_filter: String
    },
    data() {
        return {
            applied_URL: false as Boolean,
            filters: [] as local_filter[]
        }
    },
    methods: {
        async download_filters() {
            this.$store.commit("Setup_Filter", { Filter_Items: await Web_Engines.Get_Filters() });
            this.filters = this.getFilters();
        },
        getFilters(): local_filter[] {
            let allfilters: local_filter[] = [];
            let Output: local_filter[] = [];

            this.$store.getters.get_Search_Filters.forEach((filter: local_filter) => {
                var pushFilter = true;

                if (filter.name == this.remove_filter || filter.name == import.meta.env.VITE_DEFAULT_FILTER_MULTI_SELECT) {
                   pushFilter = false;
                }

                if(filter.name?.toUpperCase().includes("AGENT")){
                    filter.name = filter.name.replace("Agent", "").replace("agent", "").replace("AGENT", "");
                }

                if(filter.name?.toUpperCase().includes("DEPARTING")){
                    filter.name = "Departure Point"
                }

                if(filter.name?.toUpperCase().includes("DURATION")){
                    filter.name = "Tour Duration"                }

                if(this.isAgent_Login() && this.GetBrand().toUpperCase() == "RTG" && filter.name?.toUpperCase().includes("MONTH")){
                    pushFilter = false;
                }
                

                if(pushFilter){
                    allfilters.push(filter);
                }
            });
            
            if (this.mergeDuplicateFitlers()) {
                allfilters.forEach((filter: local_filter) => {
                    let filterfound = false;
                    Output.forEach((outputFilter: local_filter) => {
                        if (filter.name == outputFilter.name) {
                            

                            filter.tags.forEach((tag: filtertag) => {
                                if(!outputFilter.tags.includes(tag))
                                {
                                    outputFilter.tags.push(tag);
                                }                              
                            });

                            if(this.checkforduplicateNameTags(outputFilter.tags))
                            {
                                outputFilter.tags = this.mergeTags(outputFilter.tags);
                            }

                            filterfound = true;
                        }
                    });

                    if(!filterfound){
                      Output.push(filter);
                    }
                });
            }
            else {
                Output = allfilters;
            }
            

            return Output;
        },
        mergeTags(tags: filtertag[]): filtertag[] {

            let output: filtertag[] = [];

            tags.forEach((tag: filtertag) => {
                let existingTag = output.find(outputTag => outputTag.name === tag.name);
                if (existingTag && existingTag.tag_id) {
                    existingTag.tag_id = existingTag.tag_id + "," + tag.tag_id;
                } else {
                    output.push(tag);
                }
            });

            return output;
        },
        checkforduplicateNameTags(tags: filtertag[]): boolean {
            let output: boolean = false;
            let foundNames: string[] = [];

            tags.forEach((tag: filtertag) => {
                if (foundNames.includes(tag.name)) {
                    output = true;
                }
                else {
                    foundNames.push(tag.name);
                }
            });

            return output;
        },
        mergeDuplicateFitlers(){
            return import.meta.env.VITE_DEFAULT_MERGE_DUPLICATE_FILTERS == "true";
        },
        toggleFilter(filter_local: local_filter) {
            filter_local.isOpen = !filter_local.isOpen;


            this.filters.forEach((item_filter: local_filter) => {
                if (item_filter.name != filter_local.name) {
                    item_filter.isOpen = false;
                }
            });
        },

        selectFilterTag(filter: local_filter, tag: filtertag) {
            if(filter.selectedTag === tag)
            {
                filter.selectedTag = {};
            }
            else
            {
                filter.selectedTag = tag;
                filter.isOpen = false;
            }
            this.onFilterChange();
        },
        Get_Selected_Filters(): filtertag[] {
            let selectedFilterTags: filtertag[] = [];
            let outputFilterTags: filtertag[] = [];

            this.filters.forEach((filterstatus: local_filter) => {
                if (filterstatus.selectedTag.tag_id != null) {
                    filterstatus.selectedTag.filter_name = filterstatus.name;
                    selectedFilterTags.push(filterstatus.selectedTag);
                }
            });

            selectedFilterTags.forEach((tag: filtertag) => {
                if(tag.tag_id?.includes(","))
                {
                    let tag_ids = tag.tag_id.split(",");

                    //removing duplicate tags
                    tag_ids = tag_ids.filter((value, index, self) => self.indexOf(value) === index);
                    let index = 1;
                    tag_ids.forEach((tag_id: string) => {
                        let new_tag = new filtertag();
                        new_tag.filter_name = tag.filter_name + " " + index;
                        new_tag.tag_id = tag_id;
                        new_tag.name = tag.name;
                        new_tag.tag_name = tag.tag_name;

                        outputFilterTags.push(new_tag);

                        index++;

                    });

                }
                else
                {
                    outputFilterTags.push(tag);
                }
            });

            return outputFilterTags;
        },
        onFilterChange() {
            this.$store.commit("Toggle_Filters", { filters: this.Get_Selected_Filters() });
            this.$emit("onFilterChange");
        },
        onClickAway(event: any) {
            let Click_Away: boolean = true;

            if (event.path[1].id == "Filter_Click_Away") {
                    Click_Away = false;
            }

            if (Click_Away == true) {
                this.filters.forEach((item_filter: local_filter) => {
                    item_filter.isOpen = false;
                });
            }
        },
        Clear_Filter() {
            this.$store.getters.get_Search_Filters.forEach((filter: local_filter) => {
                filter.selectedTag = new filtertag();
            });
            this.applied_URL = true;
            this.onFilterChange();
        },
        FilterTags(tags: filtertag[], ): filtertag[] {

            if(this.isAgent_Login()){
                return tags;
            }

            let output: filtertag[] = [];
            tags.forEach((tag: filtertag) => {

            if(tag.name != '2 to 10 Day Tours'){
                    output.push(tag);
            }
            });

            return output;
        },
        FormatMonth(URLdate: string): string {
            let output: string = URLdate;

            if(URLdate.length == 6)
            {
                let month = URLdate.substring(4,6);
                let year = URLdate.substring(0,4);

                switch(month) { 
                    case "01": { 
                       month = "January";
                       break; 
                    }
                    case "02": {
                        month = "February";
                        break;
                    }
                    case "03": {
                        month = "March";
                        break;
                    }
                    case "04": {
                        month = "April";
                        break;
                    }
                    case "05": {
                        month = "May";
                        break;
                    }
                    case "06": {
                        month = "June";
                        break;
                    }
                    case "07": {
                        month = "July";
                        break;
                    }
                    case "08": {
                        month = "August";
                        break;
                    }
                    case "09": {
                        month = "September";
                        break;
                    }
                    case "10": {
                        month = "October";
                        break;
                    }
                    case "11": {
                        month = "November";
                        break;
                    }
                    case "12": {
                        month = "December";
                        break;
                    }
                    default: {
                        month = "January";
                        break;
                    }

                }
                output = month + " " + year;
                
            }

            return output;
        },
        SetFromURL(filter_name : string, tag_name : string ) {
            this.filters.forEach((filter: local_filter) => {
                if(filter.name == filter_name){
                    filter.tags.forEach((tag: filtertag) => {
                        if(tag.name.toLowerCase() == tag_name.toLowerCase()){ 
                            filter.selectedTag = tag;
                            this.onFilterChange();
                        }
                    });
                }
            });
        },
        isAgent_Login(): boolean {
            return this.$store.getters.get_Agent_Login?.valid_login == true;
        },
        GetBrand(): string {
            return String(import.meta.env.VITE_DEFAULT_CLASS);
        },
        FromURL() {

            let url = window.location.search.substring(1).replace(/%20/g," ").replace(/-/g," ");
            let params = url.split("&");
            
            if(!this.applied_URL){
                if(params.length > 0)
                {
                    params.forEach((param: string) => {
                        if(param.toLowerCase().includes("duration"))
                        {
                            let duration = param.split("=")[1];

                            this.SetFromURL("Duration", this.FormatDuration(duration));
                            this.applied_URL = true;
                        }
                        if(param.toLowerCase().includes("month"))
                        {
                            let month = param.split("=")[1];
                            this.SetFromURL("Month", this.FormatMonth(month));
                            this.applied_URL = true;
                        }
                        if(param.toLowerCase().includes("departing"))
                        {
                            let departing = param.split("=")[1];
                            this.SetFromURL("Departing", departing);
                            this.applied_URL = true;
                        }
                    }
                    );



                }
            }
                
          }
    },
    mounted() {
        this.download_filters();
    },
    updated() {
        this.FromURL();
    }
})