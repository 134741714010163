﻿import { defineComponent } from 'vue';

import { Booking } from "../../../services/booking";

const Book = new Booking();

export default defineComponent({
    name: "booking_notes",
    components: {
    },
    props: {
    },
    data() {
        return {
            saving_note: false as boolean,
            type: "" as string,
            note: "" as string,
            display_note: true as boolean,
            note_added: false as boolean
        }
    },
    methods: {
        Add_Type(type: string) {
            this.type = type;
        },
        Check_Type(type: string): boolean {
            return this.type == type;
        },
        async Add_Note() {
            this.saving_note = true;
            await Book.Add_Booking_Notes(this.$store.getters.get_Booking, this.$store.getters.get_Agent_Login, this.$store.getters.get_Currency, this.type, this.note).then(response => { return response.json(); });
            this.$store.commit("Setup_Booking", { Booking_Details: await Book.Get_Book(this.$store.getters.get_Booking.group_booking_id, this.$store.getters.get_Agent_Login) });
            this.saving_note = false;
            this.note_added = true;
            this.display_note = false;
        },
        Prevent_Submit() {
            console.log("Override Submit");
        },
        Display_Notes(): boolean {
            return import.meta.env.VITE_DEFAULT_DISPLAY_BOOKING_NOTES == "true";
        }
    },
    mounted() {
    }
})