﻿import { defineComponent } from 'vue';

import { request_register } from "../../booking_engine/objects/agent/identity/v1_3/request_register"
import { response_register } from "../../booking_engine/objects/agent/identity/v1_3/response_register"

import { Agent_Login } from "../../booking_engine/services/agent_login";
import VueHoneypot from '../../../../node_modules/vue-honeypot'

let Login: Agent_Login = new Agent_Login();

export default defineComponent({
    components: {
        VueHoneypot
    },
    props: {
    },
    data() {
        return {
            Register_Request: new request_register(),
            botDetected: false as boolean
        }
    },
    methods: {
        async Register() {
            try {
                this.$refs.honeypot.validate()
            
                if(!this.requiredFieldsCheck())
                {
                    window.alert("Please fill in all required fields.");
                    return;
                }
                else
                {
                    let Response_Register: response_register = await Login.Register(this.Register_Request);
                    if (Response_Register.message_sent == false) 
                    {
                        window.alert("Failed to register " + Response_Register.message);
                    } 
                    else
                    {
                        window.alert("Sucessfully registered the agency.");
                    }
                }

            } catch (error) {

                this.botDetected = true
            }

        },
        requiredFieldsCheck(): boolean {
            if (this.Register_Request.name == "" || this.Register_Request.name == undefined || this.Register_Request.name == null) {
                return false;
            }
            if (this.Register_Request.agency_name == "" || this.Register_Request.agency_name == undefined || this.Register_Request.agency_name == null) {
                return false;
            }
            if (this.Register_Request.agency_phone_number == "" || this.Register_Request.agency_phone_number == undefined || this.Register_Request.agency_phone_number == null) {
                return false;
            }
            if (this.Register_Request.agency_address_line_1 == "" || this.Register_Request.agency_address_line_1 == undefined || this.Register_Request.agency_address_line_1 == null) {
                return false;
            }
            if (this.Register_Request.agency_email == "" || this.Register_Request.agency_email == undefined || this.Register_Request.agency_email == null) {
                return false;
            }
            return true;

        },
        GetCaptchaKey(): string {
            return String(import.meta.env.VITE_CAPTCHA_KEY);
        },
        Forgot_Password() {
            this.$router.push('/login/forgot_password');
        },
        Login() {
            this.$router.push('/login');
        },
        getContactInfo(): string {
            if (import.meta.env.VITE_DEFAULT_CONTACT_LOGIN_TEXT != undefined) {
                return String(import.meta.env.VITE_DEFAULT_CONTACT_LOGIN_TEXT);
            }
            else {
                return String(import.meta.env.VITE_DEFAULT_CONTACT_TEXT);
            }
        },
        getEmailOnly(): string {

            let contactInfoWords = this.getContactInfo().split(" ");
            let returnString = "";

            contactInfoWords.forEach((word: string) => {
                if (word.includes("@")) {
                    returnString = word;
                    return;
                }
            });

            return returnString;


        },
        getLoginTitle(): string {
            return String(import.meta.env.VITE_DEFAULT_LOGIN_TITLE);
        },
        isMobile(): boolean{
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        },
        isAAT(): boolean {
            if (import.meta.env.VITE_DEFAULT_PARENT == "AAT") {
                return true;
            }
            return false;
        },
        getMobileClass(): string {
            if(this.isMobile() && !this.isAAT()){
                return "RTGFormMobile"
            }
            return "grid grid-cols-2 items-center"
        }
    },
    mounted() {
    }
});