﻿<template>
    <div v-if="cartNotEmpty()">
        <div class="pt-2" v-if="!showAddons">
            <button type="button" class="button button-pink-book full ">
                <svg class="animate-spin h-5 w-5 mr-3 inline" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 512 512" stroke="currentColor">
                    <path fill="currentColor" d="M440.65 12.57l4 82.77A247.16 247.16 0 0 0 255.83 8C134.73 8 33.91 94.92 12.29 209.82A12 12 0 0 0 24.09 224h49.05a12 12 0 0 0 11.67-9.26 175.91 175.91 0 0 1 317-56.94l-101.46-4.86a12 12 0 0 0-12.57 12v47.41a12 12 0 0 0 12 12H500a12 12 0 0 0 12-12V12a12 12 0 0 0-12-12h-47.37a12 12 0 0 0-11.98 12.57zM255.83 432a175.61 175.61 0 0 1-146-77.8l101.8 4.87a12 12 0 0 0 12.57-12v-47.4a12 12 0 0 0-12-12H12a12 12 0 0 0-12 12V500a12 12 0 0 0 12 12h47.35a12 12 0 0 0 12-12.6l-4.15-82.57A247.17 247.17 0 0 0 255.83 504c121.11 0 221.93-86.92 243.55-201.82a12 12 0 0 0-11.8-14.18h-49.05a12 12 0 0 0-11.67 9.26A175.86 175.86 0 0 1 255.83 432z" class=""></path>
                </svg>
                Loading add-ons for {{ Trip_Name() }}
            </button>
        </div>
        <div v-else class="cart-addon" :hidden="get_Add_Ons()?.length == 0">
            <h6>Optional Add-ons for {{Trip_Name()}}</h6>
            <p>{{Add_On_Message()}}</p>

            <!--Desktop view-->
            <div class="addon-content flex flex-col" :class="{'hidden': isMobile()}">
                <div class="addon w-full bg-white " v-for="product in sort_Add_ons(Addons)">
                    <div :id="'add_on_' + product.tour_id" class="addon-content flex mb-0">
                        <div class="cover ">
                            <img class="addon-image h-full w-full object-cover " :src="image(product)" :alt="imageAltText(product)">
                        </div>
                        <div class="p-4 flex flex-col justify-between leading-normal">
                            <div class="add">
                                <h6>{{product.name}}</h6>
                                <p class="mt-2 mb-4 text-sm" v-html="product.description_short"></p>
                            </div> 
                            <div class="addon-selection">
                                <select_passengers class="" :price="price(product)" :tour_details="product" :passengers="get_Booking_Passengers()" :parent_tour_id="trip?.tour_id" :tour_id="product.tour_id"></select_passengers>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!--mobile view-->
            <div class="addon-content flex flex-col" :class="{'hidden': !isMobile()}">
                <div class="addon w-full rounded-md bg-white " v-for="product in sort_Add_ons(get_Add_Ons())">
                    <div :id="'add_on_' + product.tour_id">
                        <div class="cover-mobile">
                            <img class="addon-image h-full w-full object-cover " :src="image(product)" alt="Man looking at item at a store">
                        </div>  
                        <div class="p-4 flex flex-col justify-between leading-normal">
                            <div class="add">
                                <h6>{{product.name}}</h6>
                                <p class="mt-2 mb-0 text-sm" v-html="product.description_short"></p>
                            </div> 
                        </div>
                    </div>
                    <div  class="addon-selection">
                        <select_passengers class="" :price="price(product)" :tour_details="product" :passengers="get_Booking_Passengers()" :parent_tour_id="trip?.tour_id" :tour_id="product.tour_id"></select_passengers>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script lang="ts" src="./add-ons.ts"></script>